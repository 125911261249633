import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Alert,
  Typography,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Conventional from "./Conventional/Conventional";

const ColorRadioButton = styled(Radio)(({ theme }) => ({
  color: "#A0AD39",
  fontFamily: "inherit",
  "&.Mui-checked": {
    color: "#A0AD39",
  },
}));

const RealDivider = styled(Divider)(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "20px",
}));

const Calculator = () => {
  //Radio Button Fields
  const [mode, setMode] = useState("trunked");
  const [gps, setGps] = useState("Regular");

  //TextField Inputs
  const [users, setUsers] = useState(1);
  const [slots, setSlots] = useState(1);

  //other Fields
  const [showAlert, setShowAlert] = useState(false);

  //result Fields
  const [refreshRate, setRefreshRate] = useState(0);
  const [updateIntervalSec, setUpdateIntervalSec] = useState(0);
  const [updateIntervalMin, setUpdateIntervalMin] = useState(0);

  function handleRadioChangeMode(event) {
    setMode(event.target.value);
  }
  function handleRadioChangeGPS(event) {
    setGps(event.target.value);
  }

  useEffect(() => {
    setShowAlert(false);
  }, []);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Determine the pluralization for minutes and seconds
    const minuteText = minutes === 1 ? 'minute' : 'minutes';
    const secondText = remainingSeconds === 1 ? 'second' : 'seconds';

    // Build the output string based on the conditions
    let output = '';

    // Include minutes in the output only if more than 0
    if (minutes > 0) {
        output += `${minutes} ${minuteText}`;
    }

    // Include seconds in the output if there are any remaining seconds
    // or if there are 0 minutes, ensuring we always return a meaningful value
    if (remainingSeconds > 0 || minutes === 0) {
        if (output.length > 0) {
            output += " and "; // Add "and" only if there are also minutes
        }
        output += `${remainingSeconds} ${secondText}`;
    }

    return output;
}


  useEffect(() => {
    function calculateInterval(users, slots, gps) {
      let interval;
      if (mode === "conventional") {
        interval = parseFloat(
          (
            (users / slots) *
            (gps === "Enhanced" || gps === "Sprite" ? 0.6 : 2)
          ).toFixed(2)
        );
      } else if (mode === "trunked") {
        interval = parseFloat(
          (
            (users / (2 * slots)) *
            (gps === "Enhanced" || gps === "Sprite" ? 0.6 : 2)
          ).toFixed(2)
        );
      }

      // Ensure the interval is at least 30 seconds
      interval = Math.max(interval, 30);

      // Define the possible intervals
      const possibleIntervals = [30, 60, 120, 240, 480];

      // Find the smallest interval that is greater than or equal to the calculated interval
      let nextInterval = possibleIntervals.find((value) => value >= interval);

      // If there's no interval in the array that's greater than the calculated interval, use the largest interval
      if (!nextInterval) {
        nextInterval = possibleIntervals[possibleIntervals.length - 1];
      }

      return nextInterval;
    }
    //Conventional MATH
    if (mode === "conventional") {
      if (users !== 0) {
        setShowAlert(false);
        function calculate() {
          setRefreshRate(gps === "Enhanced" || gps === "Sprite" ? 0.6 : 2);
          setUpdateIntervalSec(
            gps === "Enhanced" || gps === "Sprite"
              ? calculateInterval(users, slots, gps)
              : parseFloat(
                  (
                    (users / slots) *
                    (gps === "Enhanced" || gps === "Sprite" ? 0.6 : 2)
                  ).toFixed(2)
                )
          );
          setUpdateIntervalMin(
            gps === "Enhanced" || gps === "Sprite"
              ? calculateInterval(users, slots, gps) / 60
              : parseFloat(
                  (
                    ((users / slots) *
                      (gps === "Enhanced" || gps === "Sprite" ? 0.6 : 2)) /
                    60
                  ).toFixed(2)
                )
          );
        }
        calculate();
      } else {
        setShowAlert(`You must have at least one user`);
      }
    } else if (mode === "trunked") {
      //TRUNKED MATH
      if (users !== 0) {
        setShowAlert(false);
        function calculate() {
          setRefreshRate(gps === "Enhanced" || gps === "Sprite" ? 0.6 : 2);
          setUpdateIntervalSec(
            gps === "Enhanced" || gps === "Sprite"
              ? calculateInterval(users, slots, gps)
              : (
                  (users / (2 * slots)) *
                  (gps === "Enhanced" || gps === "Sprite" ? 0.6 : 2)
                ).toFixed(2)
          );
          setUpdateIntervalMin(
            gps === "Enhanced" || gps === "Sprite"
              ? calculateInterval(users, slots, gps) / 60
              : (
                  ((users / (2 * slots)) *
                    (gps === "Enhanced" || gps === "Sprite" ? 0.6 : 2)) /
                  60
                ).toFixed(2)
          );
        }
        calculate();
      } else {
        setShowAlert(`You must have at least one user`);
      }
    }
  }, [users, slots, gps, mode]);

  async function handleNumberChange(e, setter) {
    let val = e.target.value;
    val = parseInt(val);
    if (val.isNan) {
      alert("Value must be a number");
    } else {
      await setter(val);
    }
  }

  return (
    <div style={{ color: "#333", fontFamily: "inherit" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          height: "auto",
          justifyContent: "center",
          marginBottom: "40px",
          marginTop: "10px",
        }}
      >
        <img
          src="assets/fullLogo.png"
          alt="Teldio logo"
          style={{ width: "150px" }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div>
          <Typography variant="h5" sx={{ fontFamily: "inherit" }}>
            Motorola System Mode
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="mode"
              name="row-radio-buttons-group"
              value={mode}
              onChange={handleRadioChangeMode}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <FormControlLabel
                value="trunked"
                control={<ColorRadioButton />}
                label="Trunked"
              />
              <FormControlLabel
                value="conventional"
                control={<ColorRadioButton />}
                label="Conventional"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div style={{ paddingLeft: "20px" }}>
          <Typography variant="h5" sx={{ fontFamily: "inherit" }}>
            Tracking Method
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="gps"
              name="row-radio-buttons-group"
              value={gps}
              onChange={handleRadioChangeGPS}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <FormControlLabel
                value="Regular"
                control={<ColorRadioButton />}
                label="Motorola GPS"
              />
              <FormControlLabel
                value="Enhanced"
                control={<ColorRadioButton />}
                label="Motorola Enhanced GPS"
              />
              <FormControlLabel
                value="Sprite"
                control={<ColorRadioButton />}
                label="Sprite Firmware"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <TextField
          label="Number of Users"
          sx={{ marginTop: "20px", width: "300px", fontFamily: "inherit" }}
          type="number"
          value={users}
          autoComplete="off"
          onChange={(e) => handleNumberChange(e, setUsers)}
        />
        <TextField
          sx={{ marginTop: "20px", width: "300px", fontFamily: "inherit" }}
          label={`Number of ${
            mode === "trunked" ? "Data Repeaters" : "Data Slots"
          }`}
          type="number"
          value={slots}
          autoComplete="off"
          onChange={(e) => handleNumberChange(e, setSlots)}
        />
      </div>

      <RealDivider />
      <Typography variant="h4" sx={{ fontFamily: "inherit" }}>
        Result
      </Typography>
      {!showAlert ? (
        <>
          {!isNaN(updateIntervalSec) && !isNaN(slots) && (
            <>
              {slots === 0 && users > 20 ? (
                <Typography variant="subtitle1" sx={{ color: "red" }}>
                  The number of users entered requires at least one data
                  {mode === "trunked" ? " repeater" : " slot"}. Please enter a
                  value higher than 1 to see results
                </Typography>
              ) : (
                <Typography variant="h5" sx={{ fontFamily: "inherit" }}>
                  Update Interval:{" "}
                  {slots === 0 ? "30s" : formatTime(updateIntervalSec)}
                </Typography>
              )}

              {slots >= 0 && users <= 20 && (
                <Typography variant="subtitle1" sx={{ color: "green" }}>
                  The number of users you have entered does not require a data
                  repeater.{" "}
                </Typography>
              )}

              {slots > 0 && users > 20 && mode === "trunked" && (
                <Typography variant="subtitle1" sx={{ color: "green" }}>
                  {slots} data repeater(s) will give your {users} users the
                  ability to report GPS every {formatTime(updateIntervalSec)}.
                </Typography>
              )}
              {slots > 0 &&
                users > 20 &&
                mode === "trunked" &&
                gps === "Sprite" && (
                  <Typography variant="subtitle1" sx={{ color: "green" }}>
                    Each report will have location updates gathered every 10
                    seconds.
                  </Typography>
                )}

              {slots > 0 && users > 20 && mode === "conventional" && (
                <Typography variant="subtitle1" sx={{ color: "green" }}>
                  {slots} data slot(s) will give your {users} users the ability
                  to report GPS every {formatTime(updateIntervalSec)}.
                </Typography>
              )}
              {slots > 0 &&
                users > 20 &&
                mode === "conventional" &&
                gps === "Sprite" && (
                  <Typography variant="subtitle1" sx={{ color: "green" }}>
                    Each report will have location updates gathered every 10
                    seconds.
                  </Typography>
                )}
            </>
          )}
        </>
      ) : (
        <Alert severity="error" sx={{ marginTop: "20px" }}>
          {showAlert}
        </Alert>
      )}
    </div>
  );
};

export default Calculator;
