import React from "react";
import "./Modal.css";
import Typography from "@mui/material/Typography";
import Calculator from "../Calculator";

const Modal = () => {
  const params = new URLSearchParams(window.location.search);
  const showDiv = params.get("tools") !== "true";
  return (
    <>
      {showDiv && (
        <div
          style={{
            width: "100%",
            height: "70px",
            backgroundColor: "#424242",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            fontFamily: "fonts/Oxygen-Light",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              color: "white",
              fontFamily: "inherit",
              textAlign: "center",
            }}
            variant="h4"
          >
            Teldio GPS Rate Calculator
          </Typography>
          <img
            src="assets/logo.png"
            alt="teldio logo"
            style={{
              height: "35px",
              marginRight: "15px",
              position: "absolute",
            }}
          />
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            backgroundColor: "white",
            marginTop: "50px",
            width: "clamp(400px, 60%, 500px)",
            borderRadius: "30px",
            boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
            padding: "20px 20px 40px 20px",
          }}
        >
          <Calculator />
        </div>
      </div>
    </>
  );
};

export default Modal;
